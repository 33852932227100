import { useDispatch } from "react-redux";
import { addProductToCart } from "../../store/cart/actions";
import cartService from "../../services/cart";
import DiscountButton from "./DiscountButton";
import RitualButton from "../global/RitualButton";
import metrics from "../../utils/metrics";
import DiscountButtonAnimated from "./DiscountButtonAnimated";

type Props = {
  ritualProductOffer?: {
    product_offer_id: string;
  };
  location: string;
  oos: boolean;
  price?: number;
  discountPrice?: number;
  animated?: boolean;
  className?: string;
  isVisible?: boolean;
};

const AddBundleButton = ({
  ritualProductOffer,
  location,
  oos,
  price,
  discountPrice,
  animated,
  className,
  isVisible,
}: Props) => {
  const dispatch = useDispatch();

  if (!ritualProductOffer) return null;

  if (oos) {
    return (
      <RitualButton disabled={true} className="fullwidth">
        Out of Stock
      </RitualButton>
    );
  }

  const handleAddBundle = (e: any) => {
    dispatch(
      addProductToCart(
        {
          productOfferId: ritualProductOffer.product_offer_id,
        },
        {
          location,
        },
      ),
    );

    metrics.track("CTA Clicked", {
      location,
      title: "Add to Cart",
    });

    cartService.openCart(e);
  };

  const discountButtonProps = {
    to: "/cart",
    noNavigate: true,
    onClick: handleAddBundle,
    price: price,
    prefix: "Add to Cart",
    className,
    showDash: !!price,
    discountPrice,
  };

  if (animated) {
    return (
      <DiscountButtonAnimated {...discountButtonProps} isVisible={isVisible} />
    );
  }

  return <DiscountButton {...discountButtonProps} />;
};

export default AddBundleButton;
