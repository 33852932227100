import { Component } from "react";
import Helmet from "react-helmet";
import config from "../../utils/siteConfig";
import GeneralTags from "./GeneralTags";

interface Image {
  url: string;
  width: number;
  height: number;
}

interface ProductOfferAttributes {
  brand: string;
  availability: string;
  condition: string;
  currency: string;
  amount?: number;
  discountAmount?: number;
  productOfferId?: string;
  contentfulId?: string;
  name?: string;
  description?: string;
  category?: string;
}

interface BundleSeoProps {
  pagePath: string;
  title: string;
  description: string;
  image: Image;
  productAttributes: ProductOfferAttributes;
}

class BundleSEO extends Component<BundleSeoProps> {
  getSchemaStockStatus(stockStatus: string) {
    switch (stockStatus) {
      case "preorder":
        return "http://schema.org/PreOrder";
      case "out of stock":
        return "http://schema.org/OutOfStock";
      case "in stock":
      default:
        return "http://schema.org/InStock";
    }
  }

  getCountryCodeFromCurrency(currency: string) {
    switch (currency) {
      case "CAD":
        return "CA";
      case "GBP":
        return "GB";
      case "USD":
      default:
        return "US";
    }
  }

  render() {
    const {
      pagePath,
      title,
      description,
      image,
      image: { url: imgUrlPartial, width: imgWidth, height: imgHeight },
      productAttributes,
    } = this.props;

    const pageUrl = `${config.siteUrl}/${pagePath}`;
    const imgUrl = `https:${imgUrlPartial}`;

    const { amount, discountAmount } = productAttributes;
    const hasDiscount = !!discountAmount;

    if (!productAttributes) {
      console.warn("Missing productAttributes from page SEO");
    }

    let productMeta = [
      { property: "product:brand", content: productAttributes.brand },
      {
        property: "product:availability",
        content: productAttributes.availability,
      },
      { property: "product:condition", content: productAttributes.condition },
      {
        property: "product:retailer_item_id",
        content: productAttributes.productOfferId,
      },
      { property: "og:price:amount", content: amount },
      { property: "og:price:currency", content: productAttributes.currency },
      { property: "product:price:amount", content: amount },
      {
        property: "product:price:currency",
        content: productAttributes.currency,
      },
      { property: "product:category", content: productAttributes.category },
    ];

    // Overwrite properties below with new image attributes
    let meta = [
      { name: "image", content: imgUrl },
      { property: "og:type", content: "product.item" },
      { property: "og:image", content: imgUrl },
      { property: "og:image:width", content: imgWidth },
      { property: "og:image:height", content: imgHeight },
      { name: "twitter:image", content: imgUrl },
      ...productMeta,
    ];

    const availability = this.getSchemaStockStatus(
      productAttributes.availability,
    );

    const countryCode = this.getCountryCodeFromCurrency(
      productAttributes.currency,
    );

    const countryTransitTime = {
      CA: 10,
      US: 4,
      GB: 7,
    };

    let productSchema = [
      {
        "@context": "http://schema.org",
        "@type": "Product",
        name: productAttributes.name,
        description: productAttributes.description,
        brand: productAttributes.brand,
        image: {
          "@type": "ImageObject",
          url: imgUrl,
          width: imgWidth,
          height: imgHeight,
        },
        sku: productAttributes.productOfferId,
        mpn: productAttributes.productOfferId,
        offers: {
          "@type": "Offer",
          availability,
          url: pageUrl,
          priceSpecification: [
            {
              "@type": "UnitPriceSpecification",
              price: amount,
              priceCurrency: productAttributes.currency,
              priceType: "MSRP",
            },
            hasDiscount && {
              "@type": "UnitPriceSpecification",
              price: discountAmount,
              priceCurrency: productAttributes.currency,
              priceType: "SalePrice",
            },
          ],
          shippingDetails: {
            "@type": "OfferShippingDetails",
            shippingLabel: "Free Shipping",
            deliveryTime: {
              "@type": "ShippingDeliveryTime",
              businessDays: {
                "@type": "OpeningHoursSpecification",
                dayOfWeek: [
                  "https://schema.org/Monday",
                  "https://schema.org/Tuesday",
                  "https://schema.org/Wednesday",
                  "https://schema.org/Thursday",
                  "https://schema.org/Friday",
                ],
              },
              handlingTime: {
                "@type": "QuantitativeValue",
                minValue: 0,
                maxValue: 1,
                unitCode: "d",
              },
              transitTime: {
                "@type": "QuantitativeValue",
                minValue: 1,
                maxValue: countryTransitTime[countryCode] || 7,
                unitCode: "d",
              },
            },
            shippingDestination: {
              "@type": "DefinedRegion",
              addressCountry: countryCode,
            },
            shippingRate: {
              "@type": "MonetaryAmount",
              currency: productAttributes.currency,
              value: 0,
            },
          },
          hasMerchantReturnPolicy: {
            "@type": "MerchantReturnPolicy",
            merchantReturnLink: config.returnPolicy,
            applicableCountry: "US",
            description: "30 Money-Back Guarantee without Product Return",
            merchantReturnDays: 30,
            returnFees: 0,
          },
        },
      },
    ];

    return (
      <div>
        <GeneralTags
          pagePath={pagePath}
          title={title}
          description={description}
          image={image}
          ogTitle={productAttributes.name}
        />
        <Helmet meta={meta}>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@graph": [productSchema],
            })}
          </script>
        </Helmet>
      </div>
    );
  }
}

export default BundleSEO;
