import {
  useEffect,
  useState,
  cloneElement,
  ReactElement,
  Children,
} from "react";
import styled from "styled-components";

const MobileButtonWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 var(--spacing-1_5) var(--spacing-2);
  padding-bottom: calc(var(--spacing-2) + env(safe-area-inset-bottom));
  z-index: 10;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.475);
  transform: translateY(100%);

  &.animate {
    transform: translateY(0%);
  }

  @media (min-width: 750px /* Grid S Tablet */) {
    display: none;
  }
`;

type Props = {
  children: ReactElement<{ isVisible: boolean }>;
  threshold: number;
};

const MobileButtonContainer = ({ children, threshold }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  const enhancedChildren = Children.map(children, (child) =>
    cloneElement(child, { isVisible }),
  );

  return (
    <MobileButtonWrapper className={isVisible ? "animate" : ""}>
      {enhancedChildren}
    </MobileButtonWrapper>
  );
};

export default MobileButtonContainer;
